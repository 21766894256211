const IntroSection = () => {
  return (
    <section className="component IntroSection container">
      <h1 className="huge">Housing Metro Boston</h1>
      <p>17 cities and towns united in a landmark regional commitment to housing production.</p>

      <div className="header-image">
        <img src="/assets/images/header-homes.jpg" alt="Multi-family homes" />
      </div>
    </section>
  );
};

export default IntroSection;
